//import logo from './logo.svg';
import './MyPrimeVideo.css';
import React, { useState, useEffect } from 'react'
import axios from 'axios'

// function MyPrimeVideo() {
//   return (
//     <div className="MyPrimeVideo">
//       <header className="MyPrimeVideo-header">
//         <img src={logo} className="MyPrimeVideo-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="MyPrimeVideo-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const Board = () => {

    // const list = [];
    const [posts, setPosts] = useState([])

    useEffect(() => {
        axios.get('https://tv.ma-hiro.com/api/files/newest?cursor=0&limit=100')
            .then(res => {
                setPosts(res.data)
            })
    }, [])

    return (
        <div className="My-PrimeVideo">
            {
                posts.map(post =>
                    <a href={post.url_path}>
                        <div class="Video">
                            <img src='thumbnail' width="320" height="180" /><br />
                            {post.file_name}
                        </div>
                    </a>
                )
            }
        </div>
    );
}



class MyPrimeVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewd: 20,
        }
    }
    render() {
        return (
            <div className="my-video">
                <Board
                    state={this.state}
                />
            </div>
        );
    }
}

export default MyPrimeVideo;
